<template>
  <div class="footer-btns-bottom">
    <a class="footer-btn btn--bottom" @click="$emit('change-form', 'name')">
      <svg
        style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenoddwidth: 34px; height:34px"
        viewBox="0 0 512 512"
        class="footer-btn__icon"
      >
        <path
          d="M489.4 508.14c5.14,5.14 13.6,5.15 18.74,0 5.15,-5.14 5.15,-13.6 0,-18.74l-135.98 -135.98 4.24 -5.12c16.92,-20.36 29.89,-43.69 38.19,-68.83 8.03,-24.31 11.58,-50.04 10.51,-75.62 -2.23,-53.42 -24.28,-103.75 -62.09,-141.57 -83.05,-83.04 -217.68,-83.04 -300.73,0 -83.04,83.05 -83.04,217.69 0,300.73 37.82,37.81 88.15,59.86 141.57,62.09 25.58,1.07 51.3,-2.48 75.62,-10.51 25.14,-8.3 48.46,-21.27 68.83,-38.19l5.11 -4.24 135.99 135.98zm-145.47 -426.78c72.51,72.51 72.51,190.07 0,262.57 -72.51,72.51 -190.06,72.51 -262.57,0 -72.5,-72.5 -72.5,-190.06 0,-262.57 72.51,-72.5 190.07,-72.5 262.57,0z"
        />
      </svg>
      <span class="footer-btn__text">جستجوی مشتری بر اساس نام</span>
    </a>
    <i class="footer-btn__sep" />
    <a class="footer-btn btn--bottom" @click="$emit('change-form', 'mobile')">
      <svg
        style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenoddwidth: 34px; height:34px"
        viewBox="0 0 512 512"
        class="footer-btn__icon"
      >
        <path
          d="M489.4 508.14c5.14,5.14 13.6,5.15 18.74,0 5.15,-5.14 5.15,-13.6 0,-18.74l-135.98 -135.98 4.24 -5.12c16.92,-20.36 29.89,-43.69 38.19,-68.83 8.03,-24.31 11.58,-50.04 10.51,-75.62 -2.23,-53.42 -24.28,-103.75 -62.09,-141.57 -83.05,-83.04 -217.68,-83.04 -300.73,0 -83.04,83.05 -83.04,217.69 0,300.73 37.82,37.81 88.15,59.86 141.57,62.09 25.58,1.07 51.3,-2.48 75.62,-10.51 25.14,-8.3 48.46,-21.27 68.83,-38.19l5.11 -4.24 135.99 135.98zm-145.47 -426.78c72.51,72.51 72.51,190.07 0,262.57 -72.51,72.51 -190.06,72.51 -262.57,0 -72.5,-72.5 -72.5,-190.06 0,-262.57 72.51,-72.5 190.07,-72.5 262.57,0z"
        />
      </svg>
      <span class="footer-btn__text">جستجوی مشتری بر اساس شماره همراه</span>
    </a>
  </div>
</template>

<script>
export default {
  name: 'FooterSearchMenu'
}
</script>

<style scoped lang="scss">
.footer-btns-bottom {
  background-color: #fff;
  flex-direction: column;
  height: auto;
}
.footer-btn__sep {
  width: calc(100% - 100px);
  background-color: rgba(#fff, 0.1);
  height: 1px;
  margin: 0 60px 0 0;
}
.footer-btn__icon {
  fill: #c69d4e;
  background-color: #fff;
  width: 40px;
  height: 40px;
  border: none;
}
.footer-btn__text {
  color: #000;
}
.footer-btn__sep {
  background-color: rgba(#000, 0.1);
}
</style>
